import { gql } from '@apollo/client';

export const TOKEN_LOGIN = gql`
  query LoginWithToken {
    CurrentUser {
      id
      email
      first_name
      last_name
      is_super_admin
      userType
      defaultLandlord {
        id
        identifier
        locales
        emailProvider
        originalOwnerId
        useNewDashboard
        subdomain
        bookingFields {
          id
          field_type
          label
          options
        }
        mainContract {
          id
          ends_at
        }
      }
    }
  }
`;
